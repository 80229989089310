
<template>
    <div>
        .
        <!-- Criar página, caso você não seja direcionado clique aqui... -->
    </div>
</template>

<script>
import { isMobile } from '@/utils/utils';

export default {
    name: 'CartaoVisita',

    data: () => ({
        //
    }),

    mounted() {
        console.log(this.$route.params.uuid);

        this.log();
        this.sendWhatsapp();
    },

    methods: {
        log() {
            this.$http.post(`cartao-visita/${this.$route.params.uuid}/log`);
        },

        sendWhatsapp() {
            const baseUrl = isMobile() ? 'whatsapp://' : 'https://api.whatsapp.com/';
            const phone = '5549988951210';
            const text = encodeURIComponent('Olá, vim pelo cartão de visita, gostaria de saber mais sobre o ZaperMenu');

            window.open(`${baseUrl}send?phone=${phone}&text=${text}`);
        },
    },
}
</script>

<style scoped>
</style>
